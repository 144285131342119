<template>
  <div class="game-info" @click.self="$emit('close')">
    <div class="content">
      <span class="close-button" @click="$emit('close')">x</span>
      <h1>About GeoGrid🌎</h1>
      <p>GeoGrid is a daily trivia game made to put your geography knowledge to the test!</p>
      <h2>How to Play</h2>
      Guess a country for each box in the board that matches the criteria specified by the box's row and column
      categories.<br><br>
      Each box on any given board has anywhere from 5 to 30 unique answers.<br><br>
      A new game is available every day at 0:00 am, local time of your device!<br>
      <h3>Rules</h3>
      Players have 10 guesses to fill out the grid, but can enable infinite mode for unlimited.<br>
      A country can only be used once per game board.<br>
      <h3>Objective</h3>
      Complete the board by filling all 9 boxes with a country that matches the criteria for the intersection of
      categories.<br><br>
      Finish with the lowest score possible.
      <h3>Scoring</h3>
      The score is calculated by the sum of the rarities of each box on the board.<br><br>
      Empty cells are scored as 100.<br><br>
      The rarer the guess, the lower the score.<br><br>
      The lower the score, the better.<br><br><br>
      <button @click="$emit('close')">Play Now!</button>
      <button class="twitter-follow-us" onclick="window.open('https://twitter.com/geogridgame', '_blank')">
        <img class="twitter-img" src="/xlogo.png" alt="Twitter Icon"
          style="width: 18px; height: 18px; margin-bottom: -4px;"> Follow Us!
      </button>
    </div>
  </div>
</template>
  
  <script>    
  </script>
  
  <style scoped>
.game-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.content {
  position: relative; /* Added position relative */
  background-color: rgb(53, 53, 72);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 30%;
  max-height: 80%;
  overflow-y: auto;
  color:#dfdcdc;
  font-weight: bold;
  font-size: 14px;
}

h1, h2, h3 {
  color: #fff;
}

h1, p {
  word-wrap: break-word;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(141, 139, 139);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #ffffff;
}

.close-button::before {
  content: 'x';
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  background-color: transparent;
  z-index: -1;
}

.close-button:hover::before {
  background-color: #f2f2f2;
}
.bottom-txt{
  margin-top: -25px;
  font-size: 12px;
  color: rgb(134, 136, 164);
}

.twitter-follow-us {
  padding: 5px 20px;
  font-size: 14px;
  align-items: center;
  background-color: rgb(0, 0, 0);
  color: #fff;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  height: 40px;
  border-radius: 5px;
}

.twitter-follow-us:hover {
  background-color: #afacac !important;
}

@media screen and (max-width: 768px) {
  .content {
    width: 70%
  }
}
</style>