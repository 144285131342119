<template>
  <div v-if="showIFrameWarning" ref="toastRef" class="modal-bg" role="alert">
    <div class="container">
      <div class="content">
        <div class="body">
          <span class="title">
            🥵 ERROR! 🥵
          </span>
          <div class="message">
            <p>GeoGrid's official website is <a href="https://www.geogridgame.com/?utm_campaign=iframe_popup" target="_blank">https://www.geogridgame.com/</a> It looks like you're playing on a website that has stolen it!</p>
            <p>These websites steal revenue from game creators by re-hosting their games and overlaying their own ads. Play GeoGrid on <a href="https://www.geogridgame.com/?utm_campaign=iframe_popup" target="_blank">geogridgame.com</a>, and you'll always have the latest version, fewer ads and help support the creators (i.e. us)!</p>
            <p>Thanks,</p>
            <p>- Teuteuf Team</p>
          </div>
          <a href='https://www.geogridgame.com/?utm_campaign=iframe_popup'>
            <button class="button">
              Go There
            </button>
          </a>
        </div>
        <button type="button" @click="close" class="close-button" aria-label="Close">
          <svg aria-hidden="true" class="close-icon" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z">
            </path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';

export default {
  setup() {
    const showIFrameWarning = ref(false);
    const toastRef = ref(null);

    const inIFrame = () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }

    const close = () => {
      showIFrameWarning.value = false;
    };

    onMounted(() => {
      if (inIFrame()) {
        showIFrameWarning.value = true;
        window.gtag && window.gtag('event', 'iframe_popup');
      }
    });

    return {
      toastRef,
      showIFrameWarning,
      close,
    };
  },
};
</script>

<style scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.container {
  width: 100%;
  height: 100%;
  z-index: 99991;
  position: fixed;
  top: 16px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  pointer-events: none;
}

.content {
  display: flex;
  background-color: rgb(73, 73, 90);
  border-radius: 8px;
  padding: 20px;
  pointer-events: auto;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.body {
  margin-left: 12px;
  font-size: 14px;
}

.title {
  margin-bottom: 8px;
  font-size: 30px;
  font-weight: 600;
  color: rgb(223, 223, 223);
}

.message {
  text-align: start;
  margin-bottom: 8px;
  font-size: 15px;
  color: rgb(223, 223, 223);
  max-width: 350px;
  line-height: 1.4;
}

.message > p > a {
  color: rgb(223, 223, 223);
}

.button {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding: 8px 8px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  color: rgb(223, 223, 223);
  background-color: #2563eb;
  border: none;
}

.button:hover {
  background-color: #1d4ed8;
}

.close-button {
  margin-left: auto;
  margin-right: -6px;
  margin-top: -6px;
  background-color: rgb(73, 73, 90);
  color: rgb(223, 223, 223);
  border: none;
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.close-icon {
  width: 20px;
  height: 20px;
}
</style>