<template>
  <div class="modal-bg" @click.self="$emit('close')">
    <div class="content">
      <div class="close-btn" @click="$emit('close')">
        <div />
        <div />
      </div>
      <div class="flex-container">
        <div class="header">
          <div class="header-title">
            {{ content?.heading.translations[langCode] }}
          </div>
        </div>
        <div class="inner-content" @scroll="handleScroll">
          <div class="subheading">
            {{ content?.subheading.translations[langCode] }}
          </div>
          <div v-if="isMobile" class="mobile-game-list">
            <a
              v-for="(game, i) in content?.games"
              :key="i"
              :href="`${game.url}?utm_source=geogrid&utm_campaign=game_links_page`"
              target="_blank"
              rel="noreferrer"
              class="mobile-game-item"
            >
              <img :src="getGameIconUrl(game.name)" class="mobile-icon" />
              <div class="game-info">
                <h3 class="game-title">{{ game.name }}</h3>
                <p class="game-caption">{{ game.captions[langCode] }}</p>
              </div>
            </a>
          </div>
          <div v-else class="grid-container">
            <div class="desktop-game-grid">
              <a
                v-for="(game, i) in content?.games"
                :key="i"
                :href="`${game.url}?utm_source=geogrid&utm_campaign=game_links_page`"
                target="_blank"
                rel="noreferrer"
                class="desktop-game-item"
              >
                <img :src="getGameIconUrl(game.name)" class="desktop-icon" />
                <h3 class="desktop-title">{{ game.name }}</h3>
                <p class="desktop-caption">{{ game.captions[langCode] }}</p>
              </a>
            </div>
         </div>
          <div
              class="gradient-overlay"
              :class="{ hideGradient: !showGradient }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGameInfo, getGameIconUrl } from '@/utils/fetchDataFromCDN';

export default {
  name: 'TeuteufGames',
  data() {
    return {
      content: undefined,
      isMobile: false,
      langCode: 'en',
      showGradient: true,
    };
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);

    getGameInfo()
      .then((data) => {
        this.content = data;
      })
      .catch(() => {
        return;
      });
  },
  beforeUnmount() {
      window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth < 768;
    },
    getGameIconUrl(gameName) {
      return getGameIconUrl(gameName);
    },
    handleScroll(event) {
      const element = event.target;
      const atBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight;
      this.showGradient = !atBottom;
    },
  },
};
</script>

<style scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #353548;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 60%;
  max-width: 800px;
  height: 70%;
  max-height: 600px;
  color: white;
  overflow: hidden;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header-title {
  font-size: 1.75rem;
  font-weight: 600;
}

.inner-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
  overflow-y: auto;

   /* hide scrollbar */
   ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.subheading {
  font-size: 1rem;
  text-align: center;
}

.mobile-game-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
}

.mobile-game-list::after {
  content: '';
  display: block;
  height: 20px;
  width: 100%;
  flex-shrink: 0;
}

.grid-container {
  position: relative;
}

.desktop-game-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 12px;
  row-gap: 30px;
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.grid-container::after {
  content: '';
  display: block;
  height: 20px;
  width: 100%;
  flex-shrink: 0;
}

.gradient-overlay {
  position: absolute;
  bottom: 32px;
  left: 32px;
  width: 100%;
  height: 50px;
  background: linear-gradient(180deg, rgba(53, 53, 72, 0) 0%, #353548 100%);
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.hideGradient {
  opacity: 0;
}

.mobile-game-item {
  display: flex;
  flex-direction: row;
  align-items: start;
  background-color: #252641;
  padding: 16px;
  border-radius: 8px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s;
}

.desktop-game-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #252641;
  padding: 16px;
  border-radius: 8px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.3s;
}

.mobile-game-item:hover,
.desktop-game-item:hover {
  background-color: #414158;
}

.mobile-icon {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}

.desktop-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -20px;
}

.game-info {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
}

.game-title {
  font-size: 1rem;
  font-weight: bold;
  color: #C8C8D4;
  margin: 0;
  margin-bottom: 5px;
}

.game-caption {
  font-size: 0.875rem;
  margin: 0;
  line-height: 125%;
}

.desktop-title {
  font-size: 1.125rem;
  font-weight: bold;
  color: #C8C8D4;
  margin-top: 15px;
  text-align: center;
}

.desktop-caption {
  font-size: 0.875rem;
  text-align: center;
  margin: 0;
  line-height: 125%;
}

.close-btn {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 32px;
  right: 32px;
  cursor: pointer;
}

.close-btn > * {
  background: white;
  width: 18px;
  height: 2px;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 10px);
}

.close-btn > *:first-child {
  transform: rotateZ(45deg);
}

.close-btn > *:last-child {
  transform: rotateZ(-45deg);
}

@media screen and (max-width: 1028px) {
  .content {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .content {
    width: 85%;
    padding: 20px;
  }

  .header-title {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  .subheading{
    font-size: 0.75rem;
    margin-bottom: 10px;
  }

  .close-btn {
    top: 18px;
    right: 18px;
  }

  .gradient-overlay {
    bottom: 20px;
    left: 20px;
  }
}
</style>
