// old geogrid data had some naming differences from CDN data
// this tries to account for those so that flag svgs are not broken
export const updateOldNamesToMatchCDNNames = (countryName) => {
  if (countryName === "Ivory Coast") {
    countryName = "Côte d'Ivoire";
  } else if (countryName === "Aland Islands") {
    countryName = "Åland";
  } else if (countryName === "East Timor") {
    countryName = "Timor-Leste";
  } else if (countryName === "Micronesia") {
    countryName = "Federated States of Micronesia";
  } else if (countryName === "Guernsey") {
    countryName = "Bailiwick of Guernsey";
  } else if (countryName === "Cape Verde") {
    countryName = "Cabo Verde";
  } else if (countryName === "Reunion") {
    countryName = "Réunion";
  } else if (countryName === "Curacao") {
    countryName = "Curaçao";
  } else if (countryName === "Saint Barthelemy") {
    countryName = "Saint Barthélemy";
  } else if (countryName === "Sao Tome and Principe") {
    countryName = "São Tomé and Príncipe";
  }

  return countryName;
};
