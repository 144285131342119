/**
 * Activate a specific ad unit e.g. `sb_left`
 * @param {string} adUnit
 */
export function activateAdUnit(adUnit) {
  if (typeof window.adngin?.cmd?.startAuction === 'function') {
    window.adngin.cmd.startAuction([adUnit]);
  } else {
    window.snigelPubConf.adengine.activeAdUnits.push(adUnit);
  }
}

export function loadAdScript() {
  window.googletag = window.googletag || { cmd: [] };
  window.snigelPubConf = {
    adengine: {
      activeAdUnits: [],
    },
  };
  const href = window.location.href;
  let src = '';
  if (
    href.includes('staging') ||
    href.includes('localhost') ||
    href.includes('pages.dev')
  ) {
    console.log('Loading STAGING ads script');
    src =
      'https://staging-cdn.snigelweb.com/adengine/geogridgame.com/loader.js';
    // eslint-disable-next-line no-undef
    window.googletag.cmd.push(function () {
      // eslint-disable-next-line no-undef
      googletag.pubads().set('page_url', 'geogridgame.com');
    });
  } else {
    console.log('Loading LIVE ads script');
    src = 'https://cdn.snigelweb.com/adengine/geogridgame.com/loader.js';
  }

  const node = document.createElement('script');
  node.src = src;
  node.type = 'text/javascript';
  node.async = true;
  node.setAttribute('data-cfasync', 'false');
  document.getElementsByTagName('head')[0].appendChild(node);
}
