<template>
  <div>
    <!-- Overlay to dim the background -->
    <div class="overlay"></div>

    <!-- Popup content -->
    <div class="prev-board-popup custom-class" @click.self="$emit('close')">
      <span class="close-button" @click="$emit('close')">x</span>
      <h2>Previous Boards</h2>
      <div class="grid-container">
        <div v-for="(grid) in playableGrids" :key="grid" class="grid-item">
          <div class="grid-info">
            <p class="grid-number">Board #{{ grid }}</p>
            <router-link :to="`/board/${grid}`" class="play-now" @click="$emit('select-board')">
              {{ userData[grid] ? (userData[grid].isGameOver ? 'Score: ' + userData[grid].score : 'Guessed (' + (10 -
              userData[grid].guesses) + '/10)') : 'Play Now' }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import getBoardId from '@/utils/getBoardId';

export default {
  data() {
    return {
      playableGrids: [],
      userData: {},
    };
  },
  mounted() {
    try {
      this.getBoards();
      this.getUserData();
    } catch (error) {
      console.error('Error fetching boards:', error);
    }
  },

  methods: {
    // Fetch the list of playable boards
    getBoards() {
      try {
        const todaysBoardId = getBoardId(dayjs().format('YYYY-MM-DD'));
        this.playableGrids = Array.from({ length: todaysBoardId }).map((_, i) => (i + 1).toString()).sort((a, b) => b - a);
      } catch (error) {
        console.error('Error fetching boards:', error);
      }
    },

    // Get the user data from local storage
    getUserData() {
      const userDataString = localStorage.getItem('userData');
      if (userDataString) {
        this.userData = JSON.parse(userDataString);
      }
    },
  }
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.prev-board-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(53, 53, 72);
  padding: 20px;
  border-radius: 8px;
  z-index: 9999;
  color: #fff;
}

.prev-board-popup h2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 20px;
}

.grid-container {
  max-height: 325px;
  overflow-y: auto;
  padding-right: 20px;
}

.prev-board-popup div {
  margin-bottom: 10px;
}

.prev-board-popup p {
  margin: 0;
  font-size: 16px;
}

.prev-board-popup a {
  display: inline-block;
  padding: 5px 10px;
  background-color: rgb(141, 139, 139);
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.prev-board-popup a:hover {
  background-color: rgb(188, 187, 187);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #000;
}

.close-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  z-index: -1;
}

.close-button {
  color: #f2f2f2;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-info {
  display: flex;
  align-items: center;
}

.play-now {
  padding: 5px 10px;
  background-color: rgb(223, 223, 223);
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.play-now:hover {
  background-color: rgb(223, 223, 223);
}

.grid-number {
  min-width: 80px;
  padding-right: 10px;
}

@media screen and (max-width: 768px) {
  .prev-board-popup {
    width: 250px;
    font-size: 14px;
  }
  .grid-item {
    justify-content: center;
    margin-right: -12px;
  }
}

@media screen and (max-width: 480px) {
  .prev-board-popup {
    width: 200px;
    font-size: 12px;
  }
  .grid-item {
    justify-content: center;
    margin-right: -12px;
  }
}
</style>