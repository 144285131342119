<template>
  <div class="wrapper">
    <div id="adngin-mobile_mrec-0"></div>
  </div>
</template>

<script>
import { activateAdUnit } from "../../utils/ads";
export default {
  name: "MobileMrecAd",
  mounted() {
    if (window.innerWidth > 1024) return;
    activateAdUnit("mobile_mrec");
  },
};
</script>

<style scoped>
.wrapper {
  min-height: 50px;
  margin: 1rem .2rem;
}
@media (min-width: 1024px) {
  .wrapper {
    display: none;
  }
}
</style>
