<template>
  <div id="app">
    <RouterView />
  </div>
</template>

<script>
import { RouterView } from 'vue-router';
import { useFavicon } from '@vueuse/core';
import { loadAdScript } from './utils/ads';

export default {
  name: 'App',
  components: {
    RouterView
  },
  setup() {
    const icon = useFavicon();
    icon.value = '/earth-emoji.webp';
    return {};
  },
  mounted() {
    loadAdScript();
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@400;500;600;700&display=swap');

#app {
  font-family: 'Oxygen', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  line-height: 1.6;
}

body{
  margin: 0;
}
</style>
