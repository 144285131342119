<template>
  <div class="modal-bg" @click.self="$emit('close')">
    <div class="content">
      <div class="close-btn" @click="$emit('close')">
        <div />
        <div />
      </div>
      <button
        v-if="selectedCategory && isMobile"
        class="back-btn"
        @click="selectedCategory = null"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
          />
        </svg>
        <p>Back</p>
      </button>

      <h1>Categories Atlas</h1>

      <!-- mobile -->
      <div class="inner-content" v-if="isMobile">
        <div
          v-if="!selectedCategory"
          class="category-list"
          @scroll="handleScroll"
        >
          <CategoryListSection
            v-for="(categorySection, index) in categories"
            :key="index"
            :categorySection="categorySection"
            :isActive="isActive(index)"
            :selectedCategory="selectedCategory"
            @toggle="toggle(index)"
            @select="handleClick"
          />
        </div>
        <div
          v-if="!selectedCategory"
          class="gradient-overlay"
          :class="{ hideGradient: !showGradient }"
        ></div>

        <CategoryInfo v-else :selectedCategory="selectedCategory" />
      </div>

      <!-- desktop -->
      <div class="inner-content" v-else>
        <div class="category-list" @scroll="handleScroll" ref="categoryList">
          <CategoryListSection
            v-for="(categorySection, index) in categories"
            :key="index"
            :categorySection="categorySection"
            :isActive="isActive(index)"
            :selectedCategory="selectedCategory"
            @toggle="toggle(index)"
            @select="handleClick"
          />
        </div>
        <div
          class="gradient-overlay"
          :class="{ hideGradient: !showGradient }"
        ></div>

        <CategoryInfo
          v-if="selectedCategory"
          :selectedCategory="selectedCategory"
        />
      </div>
    </div>
  </div>
</template>
<script>
import categories from '@/utils/categories';
import CategoryListSection from './categoryListSection.vue';
import CategoryInfo from './categoryInfo.vue';

export default {
  name: 'CategoryAtlas',
  components: {
    CategoryListSection,
    CategoryInfo,
  },
  props: {
    categoryId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      categories: categories,
      selectedCategory: null,
      activeIndexes: categories.map((s, i) => i),
      isMobile: false,
      showGradient: true,
    };
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);

    if (this.categoryId) {
      this.setCategoryById(this.categoryId);
    } else if (!this.isMobile && this.categories.length > 0) {
      this.selectedCategory = this.categories[0].categories[0];
    }
  },
  beforeUnmount() {
      window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    handleClick(category) {
      this.selectedCategory = category;
      return;
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth < 768;

      if (!this.isMobile && !this.selectedCategory) {
        this.selectedCategory = this.categories[0].categories[0];
      }
    },
    setCategoryById(id) {
      for (let section of this.categories) {
        for (let category of section.categories) {
          if (category.ids.includes(id)) {
            this.selectedCategory = category;

            // scroll list
            if (!this.isMobile) {
              this.$nextTick(() => {
                this.scrollToCategory();
              });
            }
            return;
          }
        }
      }
    },
    toggle(index) {
      const i = this.activeIndexes.indexOf(index);
      if (i > -1) {
        this.activeIndexes.splice(i, 1);
      } else {
        this.activeIndexes.push(index);
      }
    },
    isActive(index) {
      return this.activeIndexes.includes(index);
    },
    handleScroll(event) {
      const element = event.target;
      const atBottom =
        element.scrollHeight - element.scrollTop === element.clientHeight;
      this.showGradient = !atBottom;
    },
    scrollToCategory() {
      const categoryList = this.$refs.categoryList;
      const categoryElement = categoryList.querySelector(
        `.accordian-content-item.selected`
      );

      if (categoryElement) {
        categoryList.scrollTop = categoryElement.offsetTop;
      }
    }
  },
};
</script>

<style scoped>
.modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #353548;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 60%;
  max-width: 800px;
  height: 70%;
  max-height: 600px;
  color: white;
  overflow: hidden;
}

h1 {
  color: #fff;
  font-weight: 600;
  font-size: 1.75rem;
  margin: 0;
  margin-bottom: 32px;
}

h1,
p {
  word-wrap: break-word;
}

.close-btn {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 32px;
  right: 32px;
  cursor: pointer;
}

.close-btn > * {
  background: white;
  width: 18px;
  height: 2px;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 10px);
}

.close-btn > *:first-child {
  transform: rotateZ(45deg);
}

.close-btn > *:last-child {
  transform: rotateZ(-45deg);
}

.back-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  position: absolute;
  width: 60px;
  height: 40px;
  top: 18px;
  left: 18px;
  cursor: pointer;
  color: white;
}

.back-btn svg {
  flex-shrink: 0;
}

.back-btn p {
  margin: 0;
}

.inner-content {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-grow: 1;
  overflow: hidden;
}

.category-list {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: start;
  align-items: start;
  height: 100%;
  width: 35%;
  min-width: 35%;
  overflow-x: hidden;

  /* hide scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
}

.category-list::after {
  content: '';
  display: block;
  height: 20px;
  width: 100%;
  flex-shrink: 0;
}

.gradient-overlay {
  position: absolute;
  bottom: 32px;
  left: 32px;
  width: 33%;
  height: 50px;
  background: linear-gradient(180deg, rgba(53, 53, 72, 0) 0%, #353548 100%);
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.hideGradient {
  opacity: 0;
}

@media screen and (max-width: 1028px) {
  .content {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  .content {
    width: 85%;
    padding: 20px;
  }

  h1 {
    font-size: 1.1rem;
    margin-bottom: 16px;
  }

  .close-btn {
    top: 18px;
    right: 18px;
  }

  .category-list {
    width: 100%;
    max-width: 100%;
  }

  .gradient-overlay {
    bottom: 20px;
    left: 20px;
    width: 100%;
  }
}
</style>
