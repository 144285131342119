import dayjs from 'dayjs';

const firsGeogridGameDate = '2024-04-07'; // April 7th 2024

/**
 * @param {string} date todays date formatted YYYY-MM-DD
 * @returns {number} the boardId of todays game
 */
export default function getBoardId(date) {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (regEx.test(date)) {
    return Number(dayjs(date).diff(dayjs(firsGeogridGameDate), 'day') + 1);
  } else {
    return 1;
  }
}
