<template>
  <div class="category-info">
    <div class="category-name">{{ selectedCategory.name }}</div>
    <div class="divider" />
    <div class="category-info-content">

      <div v-if="selectedCategory.subCategories">
        <div v-for="(cat, index) in selectedCategory.subCategories" :key="index">
          <p v-for="(desc, index) in cat.description" :key="index" :class="{ 'with-margin': index !== cat.description.length - 1 }">
            {{ desc }}
          </p>
          <div style="height: 10px" />
          <div v-for="(source, index) in cat.sources" :key="index">
            <p>Source:&nbsp;&nbsp;<a target="_blank" rel="noopener noreferrer" :href="source.link">
                {{ source.name }}</a></p>
          </div>
          <br />
        </div>
      </div>

      <div v-else>
        <p v-for="(desc, index) in selectedCategory.description" :key="index" style="margin-bottom: 10px;">
          {{ desc }}
        </p>
        <div v-if="selectedCategory.stats" style="margin-top: 17px; margin-bottom: 10px;">
          <p>Useful statistics:</p>
          <p v-for="(stat, index) in selectedCategory.stats" :key="index">
            {{ stat }}
          </p>
        </div>
        <br />
        <div v-for="(source, index) in selectedCategory.sources" :key="index">
          <p>Source:&nbsp;&nbsp;<a target="_blank" rel="noopener noreferrer" :href="source.link">
              {{ source.name }}</a></p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryInfo',
  props: {
    selectedCategory: Object
  }
};
</script>

<style scoped>
.category-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #252641;
  border-radius: 12px;
  padding: 32px;
  align-items: start;
}

.category-name {
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.divider {
  background: #414158;
  display: flex;
  width: 100%;
  height: 2px;
  margin-top: 12px;
  margin-bottom: 20px;
}

.category-info-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  font-size: 0.875rem;
  align-items: start;
  justify-content: start;
  text-align: start;
  overflow-y: auto;
  
    /* hide scrollbar */
    ::-webkit-scrollbar {
      display: none;
    }
  
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.category-info-content p {
  display: flex;
  margin: 0;
}

.with-margin {
  margin-bottom: 10px !important;
}

a {
  text-decoration: none;
  color: #1C95FF;
}

a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .category-name {
    font-size: .9rem;
  }

  .category-info {
    padding: 12px;
  }

  .category-info-content {
    font-size: .75rem;
  }
}
</style>