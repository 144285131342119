import { getCountryFlagSvgUrl } from './fetchDataFromCDN';
import sanitizeString from './sanitizeString';
import { updateOldNamesToMatchCDNNames } from './updateOldCountryName';

export default function getImageUrl(countryName) {
  //check if URL already cached
  if (Object.prototype.hasOwnProperty.call(this.imageUrlCache, countryName)) {
    return this.imageUrlCache[countryName];
  } else {
    try {
      // Update the name to match CDN names if needed
      const cdnCountryName = updateOldNamesToMatchCDNNames(countryName);
      const possibleCountryNames = [
        sanitizeString(cdnCountryName),
        sanitizeString(countryName),
      ];

      // Fetch the image URL
      const country = this.countryData.find((country) =>
        possibleCountryNames.includes(sanitizeString(country.name))
      );
      if (country) {
        const countryPrefix = country.code;
        const imageUrl = getCountryFlagSvgUrl(countryPrefix);

        // Cache the fetched URL
        this.imageUrlCache[cdnCountryName] = imageUrl;

        return imageUrl;
      } else {
        // Handle case where country data is not found
        console.error("Country data not found for:", cdnCountryName);
        return "";
      }
    } catch (error) {
      console.error('Error fetching image URL:', error);
      return '';
    }
  }
}