<template>
  <div class="freq-answer-board">
    <div class="board">
      <!-- Column categories -->
      <div class="row" style="display: flex; align-items: flex-end;">
        <div
          v-for="(column, colIndex) in gameData.columns"
          :key="'col-' + colIndex"
          class="category"
        >
          {{ column.name }}
        </div>
      </div>
      <!-- Row categories and filled board -->
      <div v-for="(row, rowIndex) in filledBoard" :key="rowIndex" class="row">
        <!-- Row category -->
        <div class="category row-category">{{ gameData.rows[rowIndex].name }}</div>
        <!-- Filled board cells -->
        <div v-for="(percentage, colIndex) in row" :key="colIndex" class="box">
          <div class="cell-content">
            <!-- Display the percentage -->
            <div class="percentage">{{ percentage }}%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    board: Array,
    gameData: Object,
    plays: Number,
    boardId: Number
  },
  data() {
    return {
      localLiveRarityData: JSON.parse(localStorage.getItem(`answer_freq_data_${this.boardId}`)),
    };
  },
  computed: {
    // Filled board with percentages
    filledBoard() {
      const filledBoard = [];
      try {
        // Check if answer frequency data exists in local storage
        const localAnswerFreqData = localStorage.getItem(`answer_freq_data_${this.boardId}`);

        if (localAnswerFreqData) {
          const parsedData = JSON.parse(localAnswerFreqData);
          for (let rowIndex = 0; rowIndex < this.board.length; rowIndex++) {
            filledBoard[rowIndex] = [];
            for (let colIndex = 0; colIndex < this.board[rowIndex].length; colIndex++) {
              const matchBox = 'match_box_' + ((rowIndex * this.board[rowIndex].length) + colIndex + 1);
              const matchData = parsedData[matchBox];
              const total = matchData.total;
              const percentage = total ? ((total / this.plays) * 100).toFixed(1) : 0;
              filledBoard[rowIndex][colIndex] = percentage;
            }
          }
        } else {
          // Use liveRarityData if no local data is available
          for (let rowIndex = 0; rowIndex < this.board.length; rowIndex++) {
            filledBoard[rowIndex] = [];
            for (let colIndex = 0; colIndex < this.board[rowIndex].length; colIndex++) {
              const matchBox = 'match_box_' + ((rowIndex * this.board[rowIndex].length) + colIndex + 1);
              const matchData = this.localLiveRarityData[matchBox];
              const total = matchData.total;
              const percentage = total ? ((total / this.plays) * 100).toFixed(1) : 0;
              filledBoard[rowIndex][colIndex] = percentage;
            }
          }
        }
      } catch (error) {
        console.error('Error retrieving answer frequency data:', error);
      }
      return filledBoard;
    }
  }
};
</script>

<style scoped>
.freq-answer-board {
  display: flex;
  justify-content: center;
}

.board {
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  gap: 0;
}

.row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.row:not(:first-child) {
    margin-bottom: -5px;
  }

.row:first-child{
  margin-left: 145px;
}

.category {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 140px;
  min-height: 50px;
  max-height: 140px;
  margin-top: 5px;
  color: rgb(223, 223, 223);
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
}

.box {
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 6px;
  pointer-events: none;
  box-sizing: border-box;
}

.cell-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -16px;
}

.percentage {
  margin-top: 15px;
  font-size: 20px;
  color: white;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 992px) {
  .board{
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .board {
    display: flex;
    flex-direction: column;
    margin-left: -273px;
  }

  .row {
    margin-left: 45px;
    gap: 4px;
  }
  .row:not(:first-child) {
    margin-bottom: -11px;
  }
  .row:first-child {
    margin-left: 129px;
    margin-bottom: -3px;
  }

  .category {
    width: 105px;
    font-size: 10px;
    min-height: 40px;
    max-height: 105px;
    margin-top: 8px;
  }
  .row-category{
    width: 80px;
  }

  .box {
    width: 105px;
    height: 105px;
  }

  .cell-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -8px;
    position: relative;
  }

  .percentage {
    margin-top: 8px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .board {
    display: flex;
    flex-direction: column;
    margin-left: -65px;
  }
  .row {
    margin-left: 55px;
  }
  .row:first-child {
    margin-left: 115px;
    margin-bottom: 2px;
  }
  .row:not(:first-child) {
    margin-bottom: -1px;
  }
  .category {
    width: 85px;
    font-size: 8px;
    min-height: 30px;
    max-height: 85px;
    margin-top: 3px;
  }
  .row-category{
    width: 56px;
  }
  .box {
    width: 85px;
    height: 85px;
  }
}

@media (max-width: 375px) {
  .row:first-child {
    margin-bottom: 7px;
  }
  .row:not(:first-child) {
    margin-bottom: 10px;
  }
  .row-category{
    height: 85px;
    margin-top: -2px;
  }
}

@media (max-width: 768px), (max-width: 480px) {
  body {
    overflow: hidden;
  }
}
</style>